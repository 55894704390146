// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mcx-jsx": () => import("./../../../src/pages/mcx.jsx" /* webpackChunkName: "component---src-pages-mcx-jsx" */),
  "component---src-pages-perspectives-deepdives-jsx": () => import("./../../../src/pages/perspectives/deepdives.jsx" /* webpackChunkName: "component---src-pages-perspectives-deepdives-jsx" */),
  "component---src-pages-perspectives-jsx": () => import("./../../../src/pages/perspectives.jsx" /* webpackChunkName: "component---src-pages-perspectives-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../../../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-article-page-jsx": () => import("./../../../src/templates/article-page.jsx" /* webpackChunkName: "component---src-templates-article-page-jsx" */)
}

